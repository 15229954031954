import { NetworkConfig } from "config/app.config";
import {
  CollectionCategory,
  UnlockdCollectionData,
} from "logic/types/collection/ICollection";
import {
  CurrencyData,
  Erc20CurrencyData,
} from "vendor/@unlockdfinance/verislabs-web3/src/types";

const USDC_CURRENCY: Erc20CurrencyData = {
  decimals: 6,
  decimalsToShow: 2,
  srcPrimary: "/assets/images/tokens/usdc.png",
  srcSecondary: "/assets/images/tokens/usdc-secondary.png",
  name: "usdc",
  label: "USDC",
  address: "0x94a9D9AC8a22534E3FaCa9F4e7F2E2cf85d5E4C8",
  chainlinkAddress: "0xA2F78ab2355fe2f984D808B5CeE7FD0A93D5270E",
  type: "erc20",
  chainId: 11155111,
};

const ETH_CURRENCY: CurrencyData = {
  decimals: 18,
  decimalsToShow: 4,
  srcPrimary: "/assets/images/tokens/eth.png",
  srcSecondary: "/assets/images/tokens/eth-secondary.png",
  name: "eth",
  label: "ETH",
  chainlinkAddress: "0x694AA1769357215DE4FAC081bf1f309aDC325306",
  type: "native",
  chainId: 11155111,
};

const UNLOCKD_TEST_WATCHES: UnlockdCollectionData = {
  name: "Unlockd Test Watches",
  address: "0xA6a9AcFDD1F64EC324eE936344cDB1457BDbDDF0",
  currenciesSupported: [USDC_CURRENCY],
  genericImage:
    "https://cdn.watches.io/content/watch_images/cnukwil3c6abnntblwbapngsizelarge",
  category: CollectionCategory.WATCHES,
};

const WATCHES_2: UnlockdCollectionData = {
  name: "Watches 2",
  address: "0x388043e55a388e07A75E9A1412FE2d64e48343A5",
  currenciesSupported: [USDC_CURRENCY],
  genericImage:
    "https://cdn.watches.io/content/watch_images/cnukwil3c6abnntblwbapngsizelarge",
  category: CollectionCategory.WATCHES,
};

export const sepoliaApp: NetworkConfig = {
  FAUCET_COLLECTIONS: [UNLOCKD_TEST_WATCHES, WATCHES_2],
  COLLECTIONS: [UNLOCKD_TEST_WATCHES, WATCHES_2],
  CONTRACT_ADDRESSES: {
    wrappedToken: "0x7b79995e5f793a07bc00c21412e50ecae098e7f9",
    pushChannel: "0x94aBa23b9Bbfe7bb62A9eB8b1215D72b5f6F33a1",
    unlockd: "0x167b44409ae1eF6eF15a47a053f6a98879296E49",
    uTokenFactory: "0x68D46eafB4A3b312F643b742F1083c64163AfFdA",
    walletFactory: "0x4abB1ee1Fa5fc610f01b33cb9F0F31b95c2163dd",
    walletRegistry: "0xA24481e94A81A624FaDD3C0F833AF241023d996E",
    bulkTransfer: "0x289655AA0F478b6D10D79CEb61C70972E1519b55",
    earnWethGateway: "0x06bE39a51B71d0FDcD6C8115bFAE8B43dcB17444",
    delegationController: "0x0000000000000000000000000000000000000000",
  },
  ALCHEMY: {
    rpcUrl: "https://eth-sepolia.g.alchemy.com/v2",
    apiUrl: "https://eth-sepolia.g.alchemy.com/nft/v2",
  },
  CHAIN: {
    name: "sepolia",
    hexId: "0xaa36a7",
    id: 11155111,
  },
  FUNCTIONALITIES: {
    faucet: true,
    "eth-wrapper": true,
  },
  CURRENCIES: [ETH_CURRENCY, USDC_CURRENCY],
  TOKENS: [ETH_CURRENCY, USDC_CURRENCY],
  images: {
    srcPrimary: "/assets/images/tokens/eth.svg",
    srcSecondary: "/assets/images/tokens/eth-secondary.png",
    srcTertiary: "/assets/images/tokens/eth.png",
  },
  RESERVOIR_API_URL: "https://api-sepolia.reservoir.tools",
  // UNLOCKD_API_URL: "https://ethereum-sepolia.unlockd.finance",
  UNLOCKD_API_URL: "https://unlockd-api.staging.unlockd.finance",
} as const;
